import React, { useEffect, useState } from 'react';
import './App.css';
import { Link } from "react-router-dom";
import $ from 'jquery'
import rightArrow from './assets/Dark/Download_Arrow.svg'
import DownArrow from './assets/Dark/DownArrow.svg'
import micon from './assets/Dark/HeroIcon_Mobile.png'
import minus from './assets/Dark/Minus.svg'
import plus from './assets/Dark/Plus.svg'
import logo from './assets/Dark/hexonLogo.svg'
import { createClient } from '@supabase/supabase-js'

function App() {
  const supabaseUrl = process.env.REACT_APP_SUPABASEURL;
  const supabaseKey = process.env.REACT_APP_SUPABASEKEY
  const supabase = createClient(supabaseUrl, supabaseKey)
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [error, setError] = useState("")



  function handleInput(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    console.log(form)
  }
  function submit() {
    validate(form)
  }
  async function post() {
    setForm({ ...form,  name: '',
    email: '',
    message: '' });
    const { data, error } = await supabase.from('contact_form').insert([{ name: form.name, email: form.email, message: form.message },])
  }
  const validate = (values) => {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      setError("Email is required!");
      let input = document.getElementById("email");
    } else if (!regex.test(values.email)) {
      setError("This is not a valid email format!");
      let input = document.getElementById("email");
      input.style.border = "2px solid #FF4444";
    }
    else {
      let input = document.getElementById("email");
      input.style.border = "";
      setError("");
      post();
    }
  }
  useEffect(() => {
   
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    $('.listheader').on('click', function () {
      var $J_li = $(this).parents('.J_list');

      // check if the clicked element already has the open className
      if ($J_li.hasClass('open')) {
        // if it does, remove the open className
        $J_li.removeClass('open');
      } else {
        // otherwise, close the previously opened element
        $('.J_list.open').removeClass('open');

        // and toggle the open className for the clicked element
        $J_li.addClass('open');
      }
    });

    return () => {
      $('.listheader').off('click');
    };

  }, []);

  const videoUrl = 'https://fbimpex.s3.ap-south-1.amazonaws.com/stockimport/export-Chat.mp4'
  return (
    <>
      <div className="container">
        <a href="https://www.hexonlabs.com/" >
          <img src={logo} className="logo" /></a>
        <div className="bgLeft" />
        <div className="bgRight" />
        <div className="content">
          <p className="title"><span className="span1">Export</span> your ChatGPT</p>
          <p className="title2">conversation<span className="span2">FOR <span className="span3"> FREE</span></span></p>
          <p className="download">Download any ChatGPT conversation with one message</p>
          <div className="installDiv">
            <a href="https://chat.openai.com/?model=gpt-4-plugins" target='_blank'><p>Install ChatGPT Plugin</p>
              <img src={rightArrow} />
            </a>
          </div>
          <p className="download"> Search for <span>Export Chat </span>under your list of ChatGPT plugins</p>

          <p className="learn" ><a href="#video">Learn how to use the plugin</a></p>
          <img className="downArrow" src={DownArrow} />
          <video id="video" className="video" src={videoUrl} controls >Your Browser Dosent Support This Video</video>

          <div className="icon1"> </div>
          <div className="icon2" />
          <div className="icon3" />
          <div className="icon4" />
          <div className="FAQ" id="faq" >
            <p className="FAQtittle">Frequently Asked Questions</p>
            <ul className="faqlist">
              <li className="J_list">
                <div id="listheader1" className="listheader">
                  How do I get better exports?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" />  <img src={minus} className='minus' />

                  </div>
                </div>
                <div className="listcontent ">
                  <div className="listcontentinner">

                    <p>To improve the quality of exports, we require ChatGPT to better format the content that is being generated and exported.<br></br></p>
                    <br></br>
                    Hence, specify that the output has to be formatted.<br></br>
                    <br></br>
                    <p className="italic"> Prepare a social media plan for a fintech start up.
                      Use html formatting and export this as a pdf file<br></br></p>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/265fff14-966c-4bba-9ed7-6ec4cefebc19" target='_blank'>here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/DFCWTTJFHI.pdf" target='_blank'>here</a><br></br>
                    <br></br>

                    You can also ask ChatGPT to make use of popular existing formatting options such as wiki, wordpress, etc.<br></br>
                    <br></br>
                    <p className="italic">Write a wiki blog on the Indian Ocean.</p>
                    ...
                    <p className="italic"> Use html formatting and export this as a doc file</p><br></br>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/40dfa148-c29f-4793-b6fd-84f67de74ab8">here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/DFCWTTJFHI.pdf">here</a><br></br>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader2" className="listheader">
                  Will the exported file maintain the same text formatting?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />

                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    Yes, the exported file will maintain the same formatting. If it doesn’t you can explicitly inform ChatGPT to do so. Here’s a sample.<br></br>
                    <br></br>
                    <p className="italic">Assume the role of a chef and write a detailed recipe for a dish that makes the best use of potatoes. Make sure the recipe is formatted well.</p><br></br>
                    ...
                    <p className="italic">export this as a pdf</p><br></br>
                    <br></br>
                    Check the conversation <a href="https://chat.openai.com/share/76868026-3f10-4fbe-9c43-ed31ecc5fdd0" target='_blank' className="a">here</a> and output the <a className="a" target='_blank' href="https://d2fnnqg6yiwc74.cloudfront.net/A9ON7J5NQQ.pdf">here</a>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader3" className="listheader">
                  Can I export an entire conversation?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />
                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    Yes, you can. Here is a sample.<br></br>

                    <p className="italic">Assume the role of MLK and respond to my questions. Each response should be less than 10 words</p><br></br>
                    ...
                    <p className="italic">Convert the html of our conversation to a pdf file</p><br></br>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/61e78359-be59-49d6-a327-ee8b6cc4d7d9" target='_blank'>here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/TINMF8KG62.pdf" target='_blank'>here</a>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader4" className="listheader">
                  What are the supported file formats?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />

                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    At the moment we only support PDF and Word Documents. We are constantly expanding to other formats as well.
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="Contact" id="contact">
            <p className="ContactTittle">Contact Us</p>
            <div className="ContactForm">
              <input placeholder='Name' value={form.name}name="name" onChange={handleInput} />
              <input placeholder='Email*' value={form.email} type="email" name="email" id="email" onChange={handleInput} />
              {error != "" ? <p className="error">{error}</p> : ""}
              <textarea placeholder='Message'  value={form.message} name="message" onChange={handleInput} />
              <button type="submit" onClick={() => submit()}>Submit</button>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footerSec1">
            <p>  ©  2023 Hexon Labs</p>
          </div>
          <div>
            <a href="https://www.hexonlabs.com/" >  <img src={logo} /></a>
          </div>
          <div className="footerSec2">
            <p><a className="footerA" href="https://www.hexonlabs.com/privacy-policy/">Privacy Policy</a></p>
            <p ><a className="footerA" href="https://www.hexonlabs.com/terms-conditions/">Terms of Service</a></p>
          </div>
        </div>
      </div>
      <div className="mcontainer">
        <div className="micon1">
          <img src={micon} /></div>
        <div className="mcontent">
          <p className="mtitle"><span className="mspan1">Export</span> your ChatGPT</p>
          <p className="mtitle2">conversation</p>
          <p className="mtitle2"><span className="mspan2">FOR <span className="mspan3"> FREE</span></span></p>
          <p className="mdownload">Download any ChatGPT <br></br> conversation with one message</p>
          <div className="minstallDiv">
            <a href="https://chat.openai.com/?model=gpt-4-plugins"> <p>Install ChatGPT Plugin</p>
              <img src={rightArrow} />
            </a>
          </div>
          <p className="mdownload"> Search for <span>Export Chat</span> under your list of ChatGPT plugins</p>

          <p className="mlearn" ><a href="#video1">Learn how to use the plugin</a></p>
          <img className="mdownArrow" src={DownArrow} />
          <video id="video1" className="video" src={videoUrl} controls >Your Browser Dosent Support This Video</video>
          <div className="FAQ">
            <p className="FAQtittle">Frequently Asked Questions</p>
            <ul className="faqlist">
              <li className="J_list">
                <div id="listheader1" className="listheader">
                  How do I get better exports?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" />  <img src={minus} className='minus' />
                  </div>
                </div>
                <div className="listcontent ">
                  <div className="listcontentinner">
                    <p>To improve the quality of exports, we require ChatGPT to better format the content that is being generated and exported.<br></br></p>
                    <br></br>
                    Hence, specify that the output has to be formatted.<br></br>
                    <br></br>
                    <p className="italic"> Prepare a social media plan for a fintech start up.
                      Use html formatting and export this as a pdf file<br></br></p>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/265fff14-966c-4bba-9ed7-6ec4cefebc19" target='_blank'>here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/DFCWTTJFHI.pdf" target='_blank'>here</a><br></br>
                    <br></br>

                    You can also ask ChatGPT to make use of popular existing formatting options such as wiki, wordpress, etc.<br></br>
                    <br></br>
                    <p className="italic">Write a wiki blog on the Indian Ocean.</p>
                    ...
                    <p className="italic"> Use html formatting and export this as a doc file</p><br></br>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/40dfa148-c29f-4793-b6fd-84f67de74ab8">here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/DFCWTTJFHI.pdf">here</a><br></br>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader2" className="listheader">
                  Will the exported file maintain the same text formatting?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />

                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    Yes, the exported file will maintain the same formatting. If it doesn’t you can explicitly inform ChatGPT to do so. Here’s a sample.<br></br>
                    <br></br>
                    <p className="italic">Assume the role of a chef and write a detailed recipe for a dish that makes the best use of potatoes. Make sure the recipe is formatted well.</p><br></br>
                    ...
                    <p className="italic">export this as a pdf</p><br></br>
                    <br></br>
                    Check the conversation <a href="https://chat.openai.com/share/76868026-3f10-4fbe-9c43-ed31ecc5fdd0" target='_blank' className="a">here</a> and output the <a className="a" target='_blank' href="https://d2fnnqg6yiwc74.cloudfront.net/A9ON7J5NQQ.pdf">here</a>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader3" className="listheader">
                  Can I export an entire conversation?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />
                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    Yes, you can. Here is a sample.<br></br>

                    <p className="italic">Assume the role of MLK and respond to my questions. Each response should be less than 10 words</p><br></br>
                    ...
                    <p className="italic">Convert the html of our conversation to a pdf file</p><br></br>
                    <br></br>
                    Check the conversation <a className="a" href="https://chat.openai.com/share/61e78359-be59-49d6-a327-ee8b6cc4d7d9" target='_blank'>here</a> and the output <a className="a" href="https://d2fnnqg6yiwc74.cloudfront.net/TINMF8KG62.pdf" target='_blank'>here</a>
                  </div>
                </div>
              </li>
              <li className="J_list">
                <div id="listheader4" className="listheader">
                  What are the supported file formats?
                  <div className="dropdowndiv">
                    <img alt="" src={plus} className="plus" /><img src={minus} className='minus' />

                  </div>
                </div>
                <div className="listcontent">
                  <div className="listcontentinner">
                    At the moment we only support PDF and Word Documents. We are constantly expanding to other formats as well.
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="Contact" id="contact">
            <p className="ContactTittle">Contact Us</p>
            <div className="ContactForm">
              <input placeholder='Name' value={form.name} name="name" onChange={handleInput} />
              <input placeholder='Email*' value={form.email} type="email" name="email" id="email" onChange={handleInput} />
              {error != "" ? <p className="error">{error}</p> : ""}
              <textarea placeholder='Message'  value={form.message} name="message" onChange={handleInput} />
              <button type="submit" onClick={() => submit()}>Submit</button>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footerSec1">
            <p> © 2023 Hexon Labs,Inc</p>
          </div>
          <div>
            <a href="https://www.hexonlabs.com/" >  <img src={logo} /></a>
          </div>
          <div className="footerSec2">
            <p><a className="footerA" href="https://www.hexonlabs.com/privacy-policy/">Privacy Policy</a></p>
            <p ><a className="footerA" href="https://www.hexonlabs.com/terms-conditions/">Terms of Service</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
